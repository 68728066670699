import React, { useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { Row, Column, Box, Button, Text } from 'components'
import { useSharedState } from 'context/sharedState'
import { createLead, enhanceLeadData } from 'api-utils/lead'
import { useLocation } from 'context/location'
import { reducedLeadValidationSchema } from 'utils/forms'
import loadable from '@loadable/component'

import { Input, Checkbox, SubmissionFailedErrorMessage } from 'components/Forms'
import LocationSelect from 'components/LocationSelect'
import Modal from 'components/Calculator/Modal'
import LeadModalPage2 from 'components/LeadModalPage2'
const CountryCodeSelect = loadable(() =>
  import('components/Forms/CountryCodeSelect')
)

const Lead = ({ onClose, signupReason: signupReasonProp }) => {
  const { countryCode } = useLocation()

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showPage2, setShowPage2] = useState(false)
  const createdLeadDataRef = useRef({})

  const [lead, updateLead] = useSharedState()
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    errors,
    control,
    watch,
  } = useForm({
    resolver: reducedLeadValidationSchema,
  })

  React.useEffect(() => {
    if (getValues('telephone')) {
      return
    }

    if (selectedCountry) {
      setValue('telephone_prefix', selectedCountry.prefix)
      return
    }

    if (countryCode) {
      setValue('telephone_prefix', countryCode === 'DE' ? '+49' : '+43')
      return
    }
  }, [selectedCountry, countryCode])

  const onSubmit = async (data) => {
    // Adding the initial signup reason to data if present in props
    if (signupReasonProp) {
      data = { ...data, initial_signup_reason: signupReasonProp }
    }

    setLoading(true)

    const telephonePrefix = data.telephone_prefix
    const telephone = data.telephone
    //delete the telephone & telephone_prefix from data as we don't want it spread into the payload
    delete data['telephone_prefix']
    delete data['telephone']

    const payload = {
      ...lead,
      lead: {
        ...lead.lead,
        ...data,
        ...(telephone && {
          telephone: `${telephonePrefix} ${telephone
            .replace(/\ /g, '')
            .replace(/\-/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')}`,
        }),
        property_country:
          selectedCountry?.value ??
          (countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode),
        application_country:
          countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode,
      },
    }

    const { success, errors, result } = await createLead(payload)

    if (errors) {
      errors.forEach(({ name, type, message }) =>
        setError(name, { type, message })
      )
    }

    if (success) {
      const { extrainfo_token, email } = result || {}
      if (extrainfo_token && email) {
        createdLeadDataRef.current = result
        setShowPage2(true)
      } else {
        setShowSuccess(true)
        updateLead({})
      }
    } else {
      setShowError(true)
    }

    setLoading(false)
  }

  const onPage2Submit = async (data) => {
    if (Object.keys(data).length > 0) {
      setLoading(true)
      await enhanceLeadData({
        email: createdLeadDataRef.current.email,
        token: createdLeadDataRef.current.extrainfo_token,
        extraInfo: data,
      })
      setLoading(false)
    }
    setShowSuccess(true)
    setShowPage2(false)
  }

  return (
    <Modal title="Unverbindlich Informationen erhalten" onClose={onClose}>
      {showSuccess ? (
        <Text>
          Danke! Bitte öffnen Sie den Link in Ihrem Postfach um mit der
          Finanzierungsanfrage fortzufahren.
        </Text>
      ) : showPage2 ? (
        <LeadModalPage2
          onSubmit={onPage2Submit}
          signUpServiceDimension={
            createdLeadDataRef.current.sign_up_service_dimension
          }
          didUserSubmitPhoneNumber={!!createdLeadDataRef.current.telephone}
          isLoading={loading}
        />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box py={[0, null, 2]}>
              <LocationSelect
                value={selectedCountry?.value}
                onChange={setSelectedCountry}
              />
            </Box>

            <Row>
              <Column size={[1, null, null, 1 / 2]} py={[0, null, 'unset']}>
                <Box py={[2, null, 2]}>
                  <Input
                    type="hidden"
                    name="form_version"
                    value="1"
                    ref={register}
                  />
                  <Input
                    placeholder="Vorname"
                    name="first_name"
                    ref={register}
                    error={errors.first_name}
                  />
                </Box>
              </Column>
              <Column size={[1, null, null, 1 / 2]} py={[0, null, 'unset']}>
                <Box py={[0, null, 2]}>
                  <Input
                    placeholder="Nachname"
                    name="last_name"
                    ref={register}
                    error={errors.last_name}
                  />
                </Box>
              </Column>
            </Row>

            <Row>
              <Column size={[1, null, null, 1 / 2]} py={[0, null, 'unset']}>
                <Box py={[2, null, 2]}>
                  <Input
                    placeholder="E-Mail"
                    name="email"
                    ref={register}
                    error={errors.email}
                  />
                </Box>
              </Column>
              <Column size={[1, null, null, 1 / 2]} py={[0, null, 'unset']}>
                <Box
                  py={[0, null, 2]}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '112px auto',
                    gridTemplateRows: 'auto auto',
                    gridTemplateAreas: `
                    'telephonePrefix telephone'
                    'errorMessage errorMessage'
                  `,
                  }}
                >
                  <Box sx={{ gridArea: 'telephonePrefix' }}>
                    <Controller
                      as={CountryCodeSelect}
                      name="telephone_prefix"
                      register={register}
                      control={control}
                      defaultValue={countryCode === 'DE' ? '+49' : '+43'}
                    />
                  </Box>
                  <Input
                    placeholder="Telefonnummer"
                    name="telephone"
                    ref={register}
                    error={errors.telephone}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderLeft: 'none',
                      gridArea: 'telephone',
                    }}
                  />
                </Box>
              </Column>
            </Row>
            <Box py={2}>
              <Checkbox
                label="Hiermit bestätige ich bis auf Widerruf, dass Bambus meine Daten zur Bearbeitung meiner Anfrage speichern darf."
                name="consent_box"
                ref={register}
                error={errors.consent_box}
                alignItemsCenter
                sxExtraProps={{
                  fontSize: ['14px', null, '16px'],
                }}
              />
            </Box>
            <Box mt={2}>
              {showError && <SubmissionFailedErrorMessage />}
              <Button
                id="modalRequestComplete"
                variant="v2Main1"
                type="submit"
                loading={loading}
                disabled={loading}
                width={['100%', 'unset']}
              >
                Weiter
              </Button>
            </Box>
          </form>
        </>
      )}
    </Modal>
  )
}

export default Lead
